import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";

const InjectMassage = (props) => (
  <span style={{ fontFamily: "poppins,cairo" }}>
    <FormattedMessage {...props} />
  </span>
);
export default injectIntl(InjectMassage, {
  withRef: false,
});
