import axios from "axios";

// export const URL2 = "https://heallocare.com/api/provider/";
export const URL = "https://backend.heallocare.net/api/provider/";
export const URL_Admin = "https://backend.heallocare.net/api/admin/";
const instance = axios.create({
  baseURL: `${URL}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("medSPtoken")}`,
    "X-Language": localStorage.getItem("lang"),
  },
});
/* -- */
instance.interceptors.request.use((req) => {
  // req?.data.append('_method','put')

  //req.params['_method']='put'

  // Important: request interceptors **must** return the request.
  return req;
});
instance.interceptors.response.use(
  (res) => {
    window.localStorage.setItem("countA", res.headers["joining-requests"]);
    window.localStorage.setItem("countB", res.headers.modification);

    return res;
  },

  (err) => {
    if (
      err &&
      err.response &&
      err.response?.status == 403 &&
      err.response.config.url != "provider/login"
    ) {
      window.localStorage.removeItem("medSPtoken");

      window.location.replace("/");
    } else if (
      err.response?.status == 401 &&
      err.response.config.url != "provider/login"
    ) {
      // window.localStorage.removeItem("medSPtoken");
      // window.location.replace("/");
    }

    // Important: response interceptors **must** return the response.
    // return err;
    else return Promise.reject(err);
  }
);

export default instance;
